import React from "react";
import ReconReport from "../reverseRecon/ReverseReconReport";
import LastDateRangeRecon from "../reverseRecon/LastDateRangeRecon";

const ReverseReconcilation = () => {
  return (
    <div>
      <LastDateRangeRecon />
    </div>
  );
};

export default ReverseReconcilation;
