import React, { useEffect, useState } from "react";
import { Box, Paper, Tab, Tabs, Typography } from "@mui/material";
import UnfulfilledOrdersTable from "./UnfulfilledOrdersTable";
import UnfulfilledPoTable from "./UnfulfilledPoTable";
import {
  getForcastedSalesDataFor15days,
  getProcessedQntyForSku,
} from "../../utils/helpers";
import { useSelector } from "react-redux";
import {
  cuttingVendors,
  finishingVendors,
  getSizesByType,
  stitchingVendors,
} from "../../utils/helperArrays";
import ProcessingStageTable from "./ProcessingStageTable";
import NotProcessingTable from "./NotProcessingTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const calculateMinimumPerDay = (POPerDaySizeWise) => {
  const data = { ...POPerDaySizeWise };
  if ("FS" in data && data["FS"] === 0) {
    delete data["FS"];
    return Math.min(...Object.values(data));
  } else {
    return Math.min(...Object.values(POPerDaySizeWise));
  }
};
const UnfulfilledOrdersSections = ({
  tableData,
  categoryTypes,
  productType,
}) => {
  const [value, setValue] = useState(0);
  const [unfulfilledPoTableData, setUnfulfilledPoTableData] = useState([]);

  const { processedQntyList } = useSelector((state) => state.PO);
  const { perDaySalesData } = useSelector((state) => state.orderDetails);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const data = tableData.map((row) => {
      const sizes = getSizesByType(productType);
      //15 day sales
      const last15DaysSales = sizes.reduce((acc, size) => {
        // find 15 day sale for the size
        const totalSizeSale = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15,
        ].reduce((saleAcc, day) => {
          const amt =
            row.perDaySales[day] && row.perDaySales[day][size]
              ? row.perDaySales[day][size]
              : 0;
          return saleAcc + amt;
        }, 0);
        acc[size] = totalSizeSale;
        return acc;
      }, {});

      const last7DaysSales = sizes.reduce((acc, size) => {
        // find 15 day sale for the size
        const totalSizeSale = [1, 2, 3, 4, 5, 6, 7].reduce((saleAcc, day) => {
          const amt =
            row.perDaySales[day] && row.perDaySales[day][size]
              ? row.perDaySales[day][size]
              : 0;
          return saleAcc + amt;
        }, 0);
        acc[size] = totalSizeSale;
        return acc;
      }, {});

      const last30DaysSales = sizes.reduce((acc, size) => {
        // find 15 day sale for the size
        const totalSizeSale = Array.from(
          { length: 30 },
          (v, k) => k + 1
        ).reduce((saleAcc, day) => {
          const amt =
            row.perDaySales[day] && row.perDaySales[day][size]
              ? row.perDaySales[day][size]
              : 0;
          return saleAcc + amt;
        }, 0);
        acc[size] = totalSizeSale;
        return acc;
      }, {});

      const totalSales = Object.values(last15DaysSales).reduce(
        (acc, s) => acc + s,
        0
      );

      const total7DaySales = Object.values(last7DaysSales).reduce(
        (acc, s) => acc + s,
        0
      );

      const total30DaySales = Object.values(last30DaysSales).reduce(
        (acc, s) => acc + s,
        0
      );

      const avgSale = parseFloat(totalSales / 15).toFixed(2);
      //processed qnty related functions
      // console.log(
      //   "process qnty list filter",
      //   processedQntyList.filter((p) => p.barcode === "ST1311")
      // );
      const processedQnty = getProcessedQntyForSku(
        processedQntyList,
        row.sku,
        sizes
      );

      const cuttingAssignedVendors = cuttingVendors.reduce((acc, v) => {
        const val = Object.values(processedQnty.cuttingVendor[v.code]).reduce(
          (acc, d) => acc + d,
          0
        );
        if (val > 0) {
          acc.push({ ...v, total: val });
        }
        return acc;
      }, []);
      const stitchingAssignedVendors = stitchingVendors.reduce((acc, v) => {
        const val = Object.values(processedQnty.stitchingVendor[v.code]).reduce(
          (acc, d) => acc + d,
          0
        );
        if (val > 0) {
          acc.push({ ...v, total: val });
        }
        return acc;
      }, []);
      const finishingAssignedVendors = finishingVendors.reduce((acc, v) => {
        const val = Object.values(processedQnty.finishingVendor[v.code]).reduce(
          (acc, d) => acc + d,
          0
        );
        if (val > 0) {
          acc.push({ ...v, total: val });
        }
        return acc;
      }, []);
      // console.log("processed qnty", processedQnty);
      // net stock (+ve / -ve) (warehouse stock + (cutting + stitching + finishing ) - unfulfilled )
      //Net Stock (E) = STOCK + PO + Processing +RTO
      const netStockPerSize = Object.keys(processedQnty.totalPerSize).reduce(
        (acc, size) => {
          const warehouseStock = row.inventoryStock[size]
            ? row.inventoryStock[size]
            : 0;
          const unfulfilledAmt = row.sizes[size] ? row.sizes[size] : 0;
          const netStock =
            warehouseStock +
            processedQnty.totalPerSize[size] +
            processedQnty.PO[size] -
            unfulfilledAmt;
          return { ...acc, [size]: netStock };
        },
        {}
      );
      const totalNetStock = Object.values(netStockPerSize).reduce(
        (acc, x) => acc + x,
        0
      );
      //Forecasted sales for the next 15 days  (  F*.5 + G*.3 +H*.2 )
      // F = per day sales as per sales of the last 3 days  ( = last three days/3)
      // G = per day sales as per sales of the last 7 days ( = last 7 days/7)
      // H = per day sales as per sales for the last 10 days  ( = last 10 days/10)
      const forcastedSalePerSize = getForcastedSalesDataFor15days(
        row.sku,
        sizes,
        perDaySalesData.sales_data
      );
      const totalForcastedSale = Object.values(forcastedSalePerSize).reduce(
        (acc, x) => acc + x,
        0
      );
      //PO Covered for next 15 days (forcasted sale + processing + PO + warehouse stock  - unfulfilled)
      const poCovered = sizes.reduce((acc, size) => {
        const warehouseStock = row.inventoryStock[size]
          ? row.inventoryStock[size]
          : 0;
        const unfulfilledAmt = row.sizes[size] ? row.sizes[size] : 0;
        const amt =
          processedQnty.totalPerSize[size] +
          processedQnty.PO[size] +
          warehouseStock -
          (forcastedSalePerSize[size] + unfulfilledAmt);
        // const amt = forcastedSalePerSize[size] +
        //   processedQnty.totalPerSize[size] +
        //   processedQnty.PO[size] +
        //   warehouseStock -
        //   unfulfilledAmt;
        return {
          ...acc,
          [size]: Math.floor(amt),
        };
      }, {});
      const totalPOCovered = Object.values(poCovered).reduce(
        (acc, x) => acc + x,
        0
      );
      const forcastedPerDaySale = totalForcastedSale / 15;

      const POPerDaySizeWise = Object.keys(netStockPerSize).reduce(
        (acc, size) => {
          const currentSizeNetStock = netStockPerSize[size];
          const perDayForcasted = forcastedSalePerSize[size] / 15;
          acc[size] =
            perDayForcasted === 0 && size === "FS"
              ? 0
              : perDayForcasted === 0 && size !== "FS"
              ? currentSizeNetStock
              : Math.ceil(currentSizeNetStock / perDayForcasted);

          return acc;
        },
        {}
      );
      const totalPOPerDaySizeWise = Object.values(POPerDaySizeWise).reduce(
        (acc, d) => acc + d,
        0
      );
      // console.log("Row", row);
      const minimumPOPerDay = calculateMinimumPerDay(POPerDaySizeWise);
      // No of Days = Net Stock / Per day sale
      const POPerDay =
        forcastedPerDaySale === 0
          ? 0
          : Math.ceil(totalNetStock / forcastedPerDaySale);
      return {
        ...row,
        processedQnty,
        netStockPerSize,
        forcastedSalePerSize,
        poCovered,
        totalNetStock,
        totalForcastedSale,
        totalPOCovered,
        POPerDay,
        last15DaysSales,
        totalSales,
        last7DaysSales,
        total7DaySales,
        last30DaysSales,
        total30DaySales,
        avgSale,
        POPerDaySizeWise,
        minimumPOPerDay,
        totalPOPerDaySizeWise,
        cuttingAssignedVendors,
        stitchingAssignedVendors,
        finishingAssignedVendors,
      };
    });

    setUnfulfilledPoTableData(data);
  }, [tableData, productType, processedQntyList, perDaySalesData]);
  return (
    <div>
      <Paper sx={{ marginBottom: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
        >
          <Tab label="Unfulfilled" {...a11yProps(0)} />
          <Tab label="Unfulfilled+PO" {...a11yProps(1)} />
          <Tab label="Not Processed" {...a11yProps(2)} />
          <Tab label="Cutting" {...a11yProps(3)} />
          <Tab label="Stitching" {...a11yProps(4)} />
          <Tab label="Finishing" {...a11yProps(5)} />
        </Tabs>
      </Paper>
      <div>
        <TabPanel value={value} index={0}>
          <UnfulfilledOrdersTable
            tableData={tableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <UnfulfilledPoTable
            tableData={unfulfilledPoTableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NotProcessingTable
            stage={"not processed"}
            vendorList={[]}
            tableData={unfulfilledPoTableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ProcessingStageTable
            stage={"cutting"}
            vendorList={cuttingVendors}
            tableData={unfulfilledPoTableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ProcessingStageTable
            stage={"stitching"}
            vendorList={stitchingVendors}
            tableData={unfulfilledPoTableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <ProcessingStageTable
            stage={"finishing"}
            vendorList={finishingVendors}
            tableData={unfulfilledPoTableData}
            categoryTypes={categoryTypes}
            productType={productType}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default UnfulfilledOrdersSections;
