import { lambdaFetch } from "../utils/awsUtils";
import { toast } from "react-toastify";

export const getReconDetailsByFulfillmentId = async (fulfillment_id) => {
  try {
    let fetching = true;
    let ReconDetails = [];

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getSingleRecon",
        {
          method: "POST",
          body: JSON.stringify({
            fulfillment_id: fulfillment_id,
          }),
        }
      );
      const data = await response.json();
      console.log(data, "data");
      if (data.statusCode === 200) {
        fetching = false;
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct ID");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};

export const getAllReconDetailsByDateRange = async (start, end) => {
  try {
    let fetching = true;
    let ReconDetails = [];

    while (fetching) {
      const response = await lambdaFetch(
        "https://ikb7luvtcl.execute-api.ap-south-1.amazonaws.com/beta/recon/getAllRecon",
        {
          method: "POST",
          body: JSON.stringify({
            starting_date: start,
            ending_date: end,
          }),
        }
      );
      const data = await response.json();
      console.log(data, "data");
      if (data.statusCode === 200) {
        fetching = false;
        ReconDetails = [...ReconDetails, ...data.data];
      } else {
        toast.error("Enter Correct Dates");
        return {
          statusCode: 500,
        };
      }
    }
    return {
      statusCode: 200,
      body: ReconDetails,
    };
  } catch (error) {
    console.log(error);
    toast.error("Something went wrong");
    return {
      statusCode: 500,
    };
  }
};
