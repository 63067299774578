import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { productTypes, categoryTypes } from "../../utils/helperArrays";
import { TextField, MenuItem, Paper } from "@mui/material";
import UnfulfilledOrdersSections from "./UnfulfilledOrdersSections";

const OrderedProductsWithImage = ({ orderedProducts }) => {
  const productImageList = useSelector(
    (state) => state.productDetails.imageList
  );
  const inventoryStock = useSelector((state) => state.inventoryDetails.stock);
  const shopifyInventory = useSelector(
    (state) => state.orderDetails.shopifyInventory
  );
  const products = useSelector((state) => state.productDetails.products);
  const productVariants = useSelector(
    (state) => state.productDetails.productVariants
  );

  const [tableData, setTableData] = useState([]);
  const [productType, setProductType] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categoryTypesArray, setCategoryTypesArray] = useState([]);
  const [productVariantList, setProductVariantList] = useState([]);

  const { perDaySalesData } = useSelector((state) => state.orderDetails);

  useEffect(() => {
    //Create the Tabledata
    if (
      orderedProducts.length > 0 &&
      productImageList.length > 0 &&
      inventoryStock.length > 0 &&
      productVariantList.length > 0 &&
      perDaySalesData
    ) {
      const data = orderedProducts.map((p) => {
        const imageObj = productImageList.find((item) => item.id === p.id);
        const inventoryObj = inventoryStock.filter(
          (item) => item.SKU === p.sku
        );
        let productStock = {};
        if (inventoryObj.length > 0) {
          const mapped = inventoryObj.map((item) => ({
            [item.size]: item.count,
          }));
          productStock = Object.assign({}, ...mapped);
        }
        const material =
          inventoryObj.length > 0 && "material" in inventoryObj[0]
            ? inventoryObj[0].material
            : [];
        let src = null;
        if (imageObj) {
          src = imageObj.src;
        } else {
          src = "https://sss-retail-images.s3.amazonaws.com/404.jpg?w=256&q=75";
        }
        let variantArr = [];
        const variantObj = productVariantList.filter(
          (item) => item.sku === p.sku
        );
        const productId = variantObj[0]?.product_id;
        const productCreatedAt = variantObj[0]?.created_at;
        // Calculate the time difference in milliseconds
        const timeDiff = new Date() - new Date(productCreatedAt);

        // Calculate the number of days
        const productAge = timeDiff / (1000 * 60 * 60 * 24);
        variantArr.push(
          variantObj.map((x) => ({
            size: x.title,
            stock: x.stock ? x.stock : 0,
          }))
        );
        const itemIdList = variantObj.reduce((acc, x) => {
          acc[x.title] = x.inventory_item_id;
          return acc;
        }, {});
        const status = products.find((p) => p.id === productId)?.status;
        const salesFind = perDaySalesData.sales_data.find(
          (sd) => sd.sku === p.sku
        );
        const perDaySales = !!salesFind ? salesFind : {};
        return {
          ...p,
          src,
          type: imageObj?.type,
          inventoryStock: productStock,
          itemIds: itemIdList,
          stock: variantArr,
          material,
          productId,
          status,
          productAge,
          perDaySales,
        };
      });

      setTableData(data);
    }
  }, [
    orderedProducts,
    productImageList,
    productVariantList,
    inventoryStock,
    perDaySalesData,
    products,
  ]);

  useEffect(() => {
    if (tableData.length > 0 && productType) {
      const getCategories = categoryTypes(productType);
      const filtered = tableData.filter((item) => item.type === productType);
      setCategoryTypesArray(getCategories);
      setFilteredProducts(filtered);
    }
  }, [productType, tableData]);

  useEffect(() => {
    if (shopifyInventory.length > 0 && productVariants.length > 0) {
      const temp = productVariants.map((x) => {
        const variant = shopifyInventory.find(
          (p) => p.inventory_item_id === x.inventory_item_id
        );
        let stock = 0;
        if (variant) {
          stock = variant.available;
        } else {
          stock = 0;
        }
        return {
          ...x,
          stock,
        };
      });
      // console.log(temp)
      setProductVariantList(temp);
    }
  }, [shopifyInventory, productVariants]);

  return (
    <div>
      <br />
      <div style={{ display: "flex" }}>
        <Paper sx={{ p: 2 }}>
          <TextField
            style={{ width: "500px" }}
            select
            label="Select Product Type"
            value={productType}
            onChange={(e) => setProductType(e.target.value)}
            variant="filled"
            size="small"
            className="select"
          >
            {productTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Paper>
      </div>
      <br />
      {productType && tableData.length > 0 ? (
        <UnfulfilledOrdersSections
          tableData={filteredProducts}
          categoryTypes={categoryTypesArray}
          productType={productType}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderedProductsWithImage;
